import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../state";
import { ethers } from "ethers";
import LazyLoad, { forceCheck } from "react-lazyload";
import LoadingContainer from "../../../../../components/loading/loadingContainer";
import Hint from "../../../../../components/common/hint/hint";
import PlaceholderImg from "images/icons/placeholder.png";
import { DateTime } from "luxon";
import { usePendingOrders } from "trashdao/hooks/usePendingOrders";
import { nftDeselected, nftSelected } from "trashdao/state/actions";
import { NFTExport } from "models/exports";

interface NFTSelectionProps {
  nft: NFTExport;
  order: string;
  disabled?: boolean;
  showExpiration?: boolean;
}

const NFTSelection: FC<NFTSelectionProps> = ({
  nft,
  order,
  disabled = false,
  showExpiration = false,
}) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const albumToken = useSelector<AppState, AppState["trashdao"]["albumToken"]>(
    (state) => state.trashdao.albumToken
  );
  const price = useSelector<AppState, number>(
    (state) =>
      state.trashdao.collections[nft.type.contract][order]["redemptionRate"]
  );
  const expirationTime = useSelector<AppState, number>(
    (state) =>
      state.trashdao.collections[nft.type.contract][order]["expirationDate"]
  );

  const [selected, available] = usePendingOrders(nft.type.contract, order);

  const isSelected = useSelector<AppState, boolean>((state) => {
    return Boolean(
      state.trashdao.selected.find((item) => {
        return (
          nft.type.contract === item.type.contract &&
          nft.id === item.id &&
          order === item.orderId
        );
      })
    );
  });

  //If another order was added already
  const isSelectedElsewhere = useSelector<AppState, boolean>((state) => {
    return Boolean(
      state.trashdao.selected.find((item) => {
        return (
          nft.type.contract === item.type.contract &&
          nft.id === item.id &&
          order !== item.orderId
        );
      })
    );
  });

  const handleSelect = () => {
    if (!disabled && !isSelectedElsewhere && (available > 0 || isSelected)) {
      if (isSelected) {
        dispatch(
          nftDeselected({
            ...nft,
            orderId: order,
          })
        );
      } else {
        dispatch(
          nftSelected({
            ...nft,
            orderId: order,
          })
        );
      }
    }
  };

  //Load the images when this shows on the screen
  useEffect(() => {
    forceCheck();
  });

  useEffect(() => {
    if (!nft?.assetURL) {
      setLoading(false);
    }
  }, []);

  return (
    <Hint
      title={
        (isSelectedElsewhere && "Already selected in another order") ||
        (available === 0 &&
          !isSelected &&
          "Maximum redemptions chosen for this order")
      }
      followCursor
    >
      <div
        className={`flex flex-col w-auto ${
          disabled || isSelectedElsewhere || (available === 0 && !isSelected)
            ? ""
            : "cursor-pointer"
        } ${
          isSelectedElsewhere || (available === 0 && !isSelected)
            ? "opacity-30"
            : ""
        }`}
        style={{ maxWidth: "136px", minHeight: "172px" }}
        onClick={handleSelect}
      >
        <LazyLoad overflow={true} height={124}>
          <>
            {loading && (
              <LoadingContainer
                variant="rectangular"
                height={124}
                sx={{ width: "100%" }}
              />
            )}
            <img
              src={nft?.thumbnailURL || nft?.assetURL || PlaceholderImg}
              // alt={PlaceholderImg}
              className={
                loading
                  ? "hidden"
                  : "block object-contain border-2 border-dark-divider"
              }
              style={{ width: "124px", height: "124px" }}
              onLoad={() => setLoading(false)}
              onError={(e) => {
                setLoading(false);
                e.target.src = PlaceholderImg;
              }}
            />
          </>
        </LazyLoad>
        <div className="flex flex-row mt-8">
          <input
            className={`${
              disabled
                ? "hidden"
                : "bg-transparent border-2 focus:outline-none focus:ring-transparent mr-12"
            }`}
            style={{
              color: "#000",
              borderColor: `${isSelected ? "#FFF" : "#FFFFFFA3"}`,
            }}
            type="checkbox"
            checked={isSelected}
            disabled={isSelectedElsewhere || (available === 0 && !isSelected)}
            readOnly
          />
          <div className="flex flex-col">
            <p className="body2 text-white">
              {Number(
                ethers.utils.formatUnits(
                  Number(price).toLocaleString("fullwide", {
                    useGrouping: false,
                  })
                )
              ).toFixed(0)}{" "}
              ${albumToken}
            </p>
            <p className="body1 text-dark-white-64">
              #{nft?.id.length <= 8 ? nft?.id : `${nft?.id.slice(0, 8)}...`}
            </p>
            {showExpiration && (
              <p className="body1 text-dark-white-64">
                Expires{" "}
                {DateTime.fromMillis(expirationTime).toFormat("M/dd/yy")}
              </p>
            )}
          </div>
        </div>
      </div>
    </Hint>
  );
};

export default NFTSelection;
