import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import metadata from "trashdao/constants/metadataMap.json";
import { loadCollections } from "trashdao/state/actions";

// Hook that loads collection data if not already loaded from json
export const useGetContractMetaDatasForTrashDAO = () => {
  const dispatch = useDispatch();
  const collectionMetadata = useSelector<
    AppState,
    AppState["trashdao"]["collectionMetadata"]
  >((state) => state.trashdao.collectionMetadata);

  useEffect(() => {
    //Only load once
    if (Object.keys(collectionMetadata).length === 0) {
      dispatch(loadCollections(metadata));
    }
  }, []);
};
