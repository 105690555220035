import { Disclosure, Transition } from "@headlessui/react";
import React, { FC, useState } from "react";
import CollectionOrders from "./collectionOrders";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useGetContractMetadata } from "../../../../../hooks/nfts/useGetContractMetadata";
import LoadingContainer from "../../../../../components/loading/loadingContainer";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../state";
import { Collection as CollectionObj } from "models/collection";

interface CollectionProps {
  collection: string;
}

const Collection: FC<CollectionProps> = ({ collection }) => {
  const albumToken = useSelector<AppState, AppState["bounty"]["albumToken"]>(
    (state) => state.trashdao.albumToken
  );
  const metadata = useSelector<AppState, CollectionObj>(
    (state) => state.trashdao.collectionMetadata[collection]
  );
  const [loading, setLoading] = useState(true);
  return (
    <Disclosure as="div" className="flex flex-col w-full">
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            className="flex flex-row w-full items-center justify-between py-4 text-white text-opacity-64 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 bg-transparent cursor-pointer pb-24 border-b border-dark-divider"
            style={{ WebkitAppearance: "none" }}
          >
            <div className="flex flex-row space-x-16 w-full text-white">
              {loading && (
                <LoadingContainer variant="circular" height={52} width={52} />
              )}
              <img
                src={metadata?.image_url}
                onLoad={() => {
                  setLoading(false);
                }}
                onError={() => {
                  setLoading(false);
                }}
                className={`${
                  loading ? "hidden" : ""
                } rounded-full bg-dark-divider border-0`}
                style={{ width: "52px", height: "52px" }}
              />
              <p className="my-auto responsive-h3 md:responsive-h2">
                {metadata?.name}
              </p>
            </div>
            <div className="flex flex-row space-x-16 items-center">
              <div
                className={`${
                  open ? "" : "-rotate-180"
                } transform transition-transform duration-300 ease-in-out flex border rounded-full h-32 w-32 opacity-64 items-center`}
              >
                <ExpandLessIcon className="w-24 h-24 fill-current text-white mx-auto" />
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel
            unmount={false}
            className="text-white sm:pt-16 md:pt-24"
          >
            <Transition
              as="div"
              show={open}
              enter="transform transition duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transform duration-300 transition ease-in-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            ></Transition>
            {metadata && (
              <CollectionOrders key={metadata.address} collection={metadata} />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Collection;
