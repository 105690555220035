import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "state";
import { useGetContractMetaDatasForTrashDAO } from "trashdao/hooks/useGetContractMetadatasForTrashDAO";
import { TrashDAOBountyBoardState } from ".";
import Approval from "./bountyboard/steps/approval/approval";
import Confirm from "./bountyboard/steps/confirm/confirm";
import SwapNFT from "./bountyboard/steps/swapNFT";

interface BountyContainerProps {
  state: TrashDAOBountyBoardState;
}

const BountyContainer: FC<BountyContainerProps> = ({ state }) => {
  switch (state) {
    case TrashDAOBountyBoardState.SWAP:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <SwapNFT />
        </div>
      );
    case TrashDAOBountyBoardState.APPROVE:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <Approval />
        </div>
      );
    case TrashDAOBountyBoardState.CONFIRM:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <Confirm />
        </div>
      );
    case TrashDAOBountyBoardState.COMPLETE:
      return null;
    default:
      return null;
  }
};

export default BountyContainer;
