import axios from "axios";
import { API_URL } from "constants";
import { useAuthentication } from "hooks/auth/useLogin";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/api";
import { AppState } from "state";
import {
  initalizeCollectionStore,
  saveOrdersForCollections,
  saveValidatorsForOrders,
} from "trashdao/state/actions";

//Returns bounties qualified for album
export const useGetTrashDAOBounties = (albumId: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const { user, authLoading } = useAuthentication();
  const collections = useSelector<
    AppState,
    AppState["trashdao"]["collections"]
  >((state) => state.trashdao.collections);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.uid && Object.keys(collections).length === 0) {
      setLoading(true);
      const api = new Api();
      axios
        .post(`${API_URL}trashdao/getQualifiedBountiesForTrashDAO`, {
          walletAddress: user.uid,
        })
        .then((response) => {
          const orders = response.data.orders;
          const collectionData = response.data.collectionMetadata;
          const validatorData = response.data.validators;

          if (Object.keys(orders).length > 0) {
            //Only save if we got orders
            dispatch(initalizeCollectionStore(collectionData));
            dispatch(saveOrdersForCollections(orders));
            dispatch(saveValidatorsForOrders(validatorData));
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [user]);

  return [loading, error];
};

export const useHasBounties = (albumId: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [hasBounties, setHasBounties] = useState<boolean>(false);

  useEffect(() => {
    const api = new Api();

    api
      .hasBounties(albumId)
      .then((response) => {
        setHasBounties(response.data.hasBounties);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [hasBounties, loading, error];
};
