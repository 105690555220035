import { Router } from "@reach/router";
import AlbumDetail from "components/album/albumDetail/albumDetail";
import Proposal from "components/album/proposal/proposal";
import PrivateRoute from "components/app/privateRoute";
import Layout from "components/layout";
import Loading from "components/loading/loading";
import SEO from "components/seo";
import { error } from "console";
import { useGetAlbumDetails } from "hooks/album/useGetAlbumDetails";
import React from "react";
import TrashDAOBountyBoard from "trashdao/components";

const TrashDAOPage = () => {
  const [details, loading, error] = useGetAlbumDetails("trashdao");

  if (loading) {
    return (
      <Layout>
        <SEO title="Album" description="Check out this NFT Album" />
        <div className="flex text-white">
          <div className="mx-auto">
            <Loading />
          </div>
        </div>
      </Layout>
    );
  } else if (error) {
    return (
      <Layout>
        <SEO title="Album" />
        <div className="flex flex-col w-full h-full justify-center">
          <h1 className="text-white text-center">
            {error.replace("fund", "album")}
          </h1>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="TrashDAO" />
      <Router className="flex flex-col flex-grow" basepath="/album/:albumName">
        <AlbumDetail path="/" album={details} />
        <PrivateRoute
          path="/proposal/*"
          component={() => <Proposal album={details} />}
        />
        <PrivateRoute
          path="/bounty_board"
          component={() => <TrashDAOBountyBoard album={details} />}
        />
      </Router>
    </Layout>
  );
};

export default TrashDAOPage;
